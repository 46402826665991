import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { useContext } from "react";
import { selectedDateRange } from "../context-api/Context";
import dayjs from "dayjs";
import { converterDefaultValue } from "./DateConvertor";
import {
  currentYear,
  defaultYear,
  fromDate,
  fromDefaulDate,
  toDate,
  toDefaultDate,
} from "../../../../components/date-input/FromDateToDate";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ResultDateInput = ({ fromDashboard }) => {
  const { RangePicker } = DatePicker;
  const [FromDate, setFromDate] = useState(fromDate());
  const [ToDate, setToDate] = useState(toDate());
  const selectedDateData = useContext(selectedDateRange);
  const [clear, setClear] = useState(false);
  const [isFromDashboard, setIsFromDashboard] = useState(fromDashboard);
  const [popupOpen, setPopUpOpen] = useState(true);
  const [selectYear, setSelectYear] = useState(defaultYear());

  const yearOption = ["2024", "2023", "2022", "2021", "2020", "2019", "2018"];

  useEffect(() => {
    setFromDate(fromDate());
    setToDate(toDate());
    setSelectYear(defaultYear());
    selectedDateData.setSelectedFromDate(fromDate());
    selectedDateData.setSelectedToDate(toDate());
    setIsFromDashboard(fromDashboard);
  }, [window.location.href]);

  const handleSelectedDate = (e) => {
    if (e !== null) { 
      selectedDateData.setIsDateChange(true);
      if (e.length > 1) {
        if (fromDashboard) {
          const abc = converterDefaultValue(e[0].$d.toString().split(" "));
          const xyz = converterDefaultValue(e[1].$d.toString().split(" "));
          localStorage.setItem("from_date", abc);
          localStorage.setItem("to_date", xyz);
        }
        setFromDate(converterDefaultValue(e[0].$d.toString().split(" ")));
        setToDate(converterDefaultValue(e[1].$d.toString().split(" ")));
      }
    } else {
      selectedDateData.setIsYearChange(true); // Reset here
      selectedDateData.setIsDateChange(false); // Reset here
      if (fromDashboard) {
        localStorage.setItem("from_date", fromDefaulDate());
        localStorage.setItem("to_date", toDefaultDate());
        localStorage.setItem("selectedYear", currentYear());
      }
      setSelectYear(currentYear());
      setFromDate(fromDefaulDate());
      setToDate(toDefaultDate());
    }
  };

  useEffect(() => {
    if (FromDate !== null && ToDate !== null) {
      if (!popupOpen) {
        selectedDateData.setIsDateSelected(true);
        selectedDateData.setSelectedFromDate(FromDate);
        selectedDateData.setSelectedToDate(ToDate);
      }
    } else {
      selectedDateData.setIsDateSelected(true);
      selectedDateData.setSelectedFromDate(fromDate());
      selectedDateData.setSelectedToDate(toDate());
    }
  }, [popupOpen]);

  const focousOnDate = (e) => {
    setIsFromDashboard(false);
    setClear(true);
  };

  const handleOpenPopup = (e) => {
    setPopUpOpen(e);
    setClear(e);
  };

  const disabledDate = (current) => {
    if (selectYear == currentYear()) {
      const defaultFromDate = moment().startOf("year");
      const defaultToDate = dayjs(moment().format("YYYY/MM/DD"));
      return current && (current > defaultToDate || current < defaultFromDate);
    } else {
      const givenDate = moment(selectYear);
      const after1year = givenDate.clone().add(1, "month");
      const before1year = givenDate.clone().subtract(1, "month");
      return (
        current &&
        (current > moment(after1year).endOf("year") ||
          current < moment(before1year).endOf("month"))
      );
    }
  };

  const handleYearChange = (e) => {
    let dateString = e.target.value;
    setSelectYear(dateString);
    if (dateString) {
      const selectedYear = dayjs(dateString, "YYYY").year();
      let newFromDate, newToDate;
      if (selectedYear === dayjs(moment().format("YYYY/MM/DD")).year()) {
        newFromDate = dayjs(`${selectedYear}-01-01`, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        newToDate = moment().format("YYYY-MM-DD");
      } else if (selectedYear) {
        newFromDate = dayjs(`${selectedYear}-01-01`, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        newToDate = dayjs(`${selectedYear}-12-31`, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
      }
      selectedDateData?.setIsYearChange(true);
      selectedDateData.setSelectedFromDate(newFromDate);
      selectedDateData.setSelectedToDate(newToDate);
      if (fromDashboard) {
        localStorage.setItem("from_date", newFromDate);
        localStorage.setItem("to_date", newToDate);
        localStorage.setItem("selectedYear", dateString);
      }
      setFromDate(newFromDate);
      setToDate(newToDate);
    }
  };

  return (
    <>
      <FormControl className="select-year" fullWidth>
        <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectYear}
          label="Select Year"
          onChange={handleYearChange}
        >
          {yearOption?.map((res, index) => {
            return (
              <MenuItem value={res} key={index}>
                {res}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* <RangePicker
        placement="bottomRight"
        style={{ width: "100%" }}
        dropdownClassName="rangepicker-poparea"
        onChange={(e) => handleSelectedDate(e)}
        format={"YYYY/MM/DD"}
        value={[
          FromDate !== null ? dayjs(FromDate, "YYYY/MM/DD") : null,
          ToDate !== null ? dayjs(ToDate, "YYYY/MM/DD") : null,
        ]}
        allowClear={clear}
        onFocus={focousOnDate}
        onOpenChange={handleOpenPopup}
        disabledDate={disabledDate}

        // disabled={selectYear ? false : true}
      /> */}
    </>
  );
};

export default ResultDateInput;
