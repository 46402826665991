import * as React from "react";
import "../../assets/style/BS5_Grid.css";
import "../../assets/style/style.css";
import { Chip, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useSettingsContext } from "../../components/settings/SettingsContext";
import { useState, useEffect, useRef, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import MailResultFilters from "./MailResultFilters/MailResultFilters";
import MailResultTabArea from "./MailResultTabArea/MailResultTabArea";
import moment from "moment";
import { dateConvert } from "../../_helpers/date-format";

const MailResult = () => {
  const dispatch = useDispatch();
  const initialListing = useSelector((state) =>
    state.listing_model.initialResultListing !== undefined &&
    state.listing_model.initialResultListing !== null
      ? state.listing_model.initialResultListing
      : state.listing_model.initialListing
  );
  const { themeStretch } = useSettingsContext();
  const [page, setPage] = useState(1);
  const [stateTag, setStateTag] = useState(
    initialListing.state_ids !== undefined &&
      initialListing.state_ids !== null &&
      initialListing.state_ids.length > 0
      ? initialListing.state_ids.map((res) => ({
          ...res,
          title: res.state_name,
          type: "State",
        }))
      : []
  );
  const [departmentTag, setDepartmentTag] = useState(
    initialListing.organization_id !== undefined &&
      initialListing.organization_id !== null &&
      Object.keys(initialListing.organization_id).length > 0
      ? {
          ...initialListing.organization_id,
          title: initialListing.organization_id.organization_name,
          type: "Department",
        }
      : null
  );
  const [value, setValue] = useState("1");
  const [dataFound, setDataFound] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [mailResultDate, setMailResultDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [updateFromDate, setUpdateFromDate] = useState("");
  const [updateToDate, setUpdateToDate] = useState("");
  const [tenderForm, setTenderForm] = useState({
    mail_date: mailResultDate,
    page_no: 1,
    record_per_page: 20,
    sort_by: 3,
    sort_type: 1,
  });
  const [filterTags, setFilterTags] = useState(
    mailResultDate
      ? [
          {
            type: "Mail Date",
            title: dateConvert(mailResultDate),
          },
        ]
      : []
  );
  const [mailTenderTabCount, setMailTenderTabCount] = useState({});
  const [mailBidderTabCount, setMailBidderTabCount] = useState({});
  const formDateRef1 = useRef(null);
  const formDateRef2 = useRef(null);

  const tenderResultTabChange = (newValue) => {
    setPage(1);
    setValue(newValue);
    setDataFound(true);
    if (newValue === "1") {
      setTenderForm((prev) => ({
        ...prev,
        sort_by: 3,
        sort_type: 1,
      }));
    } else {
      setTenderForm((prev) => ({
        ...prev,
        sort_by: 2,
        sort_type: 1,
      }));
    }
  };

  const handleDelete = (e, res) => {
    setDataFound(false);
    setFilterTags(
      filterTags !== null &&
        filterTags.filter(
          (prev) => JSON.stringify(prev) !== JSON.stringify(res)
        )
    );

    
    setPage(1);
    switch (res.type) {
      case "Mail Date":
        setMailResultDate(moment().format("YYYY-MM-DD"));
        formDateRef1.current?.resetFields();
        setTenderForm((prev) => ({
          ...prev,
          mail_date: moment().format("YYYY-MM-DD"),
        }));
        setIsFilter(true);
        break;

      default:
        console.log("handle delete clicked");
        break;
    }
  };
  useEffect(() => {
    setIsFilter(true);
  }, []);
  useEffect(() => {
    if (isFilter) {
      setDataFound(true);
      setIsFilter(false);
    }
  }, [isFilter]);
  return (
    <>
      <Helmet>
        <title>Mail Result</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={3}>
          <MailResultFilters
            stateTag={stateTag}
            setStateTag={setStateTag}
            departmentTag={departmentTag}
            setDepartmentTag={setDepartmentTag}
            setFilterTags={setFilterTags}
            setDataFound={setDataFound}
            setIsFilter={setIsFilter}
            mailTenderTabCount={mailTenderTabCount}
            setMailTenderTabCount={setMailTenderTabCount}
            setMailBidderTabCount={setMailBidderTabCount}
            page={page}
            setPage={setPage}
            mailResultDate={mailResultDate}
            setMailResultDate={setMailResultDate}
            updateFromDate={updateFromDate}
            updateToDate={updateToDate}
            setUpdateToDate={setUpdateToDate}
            setUpdateFromDate={setUpdateFromDate}
            formDateRef1={formDateRef1}
            formDateRef2={formDateRef2}
            setTenderForm={setTenderForm}
          />
          {/* *************************Tags of filters*************************************** */}
          <div className="tag-outside-area">
            <div className="tag-outside-area-inner">
              {filterTags.map(
                (res, i) =>
                  res !== null && (
                    <Chip
                      key={i}
                      label={res.type + "  :-  " + res?.title}
                      onDelete={(e) => {
                        res?.type !== "Mail Date" && handleDelete(e, res);
                      }}
                    />
                  )
              )}
            </div>
          </div>

          <div className="tender-result-tab-area">
            <MailResultTabArea
              value={value}
              setValue={setValue}
              setDataFound={setDataFound}
              dataFound={dataFound}
              mailTenderTabCount={mailTenderTabCount}
              setMailTenderTabCount={setMailTenderTabCount}
              mailBidderTabCount={mailBidderTabCount}
              setMailBidderTabCount={setMailBidderTabCount}
              tenderForm={tenderForm}
              setTenderForm={setTenderForm}
              setIsFilter={setIsFilter}
              mailResultDate={mailResultDate}
              setMailResultDate={setMailResultDate}
              page={page}
              setPage={setPage}
              tenderResultTabChange={tenderResultTabChange}
            />
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default MailResult;
