import React, { useContext } from "react";
import { DatePicker, Form } from "antd";
import { convertDateFormat } from "../../_helpers/date-format";
import moment from "moment";
import { selectedDateRange } from "../../layouts/dashboard/header/context-api/Context";
import dayjs from "dayjs";

const RangePickerFilter = (props) => {
  const {
    setFromDate,
    setToDate,
    isDisabledDate,
    fromDatePlaceHolder,
    toDatePlaceHolder,
  } = props;
  const { RangePicker } = DatePicker;
  const { selectedFromDate } = useContext(selectedDateRange);
  const allowedYear = dayjs(selectedFromDate).year(); // Extract the year from the date string
  const defaultPickerValue = dayjs(selectedFromDate).startOf("year"); // Set defaultPickerValue to the start of the allowed year

  const handleSelectedDate = (e) => {
    if (e === null) {
      setFromDate(null);
      setToDate(null);
    } else {
      let startDate = convertDateFormat(e[0].$d);
      let endDate = convertDateFormat(e[1].$d);
      setFromDate(startDate);
      setToDate(endDate);
    }
  };

  const disableFutureDates = (current) => {
    return current && current.year() !== allowedYear;
  };

  return (
    <>
      <RangePicker
        placement="bottomRight"
        style={{ width: "100%" }}
        placeholder={
          fromDatePlaceHolder && toDatePlaceHolder
            ? [fromDatePlaceHolder, toDatePlaceHolder]
            : ["Start Date", "End Date"]
        }
        popupClassName="rangepicker-poparea"
        // ranges={{
        //   Yesterday: [
        //     moment().subtract(1, "days"),
        //     moment().subtract(1, "days"),
        //   ],
        //   "Last 7 Days": [moment().subtract(6, "d"), moment()],
        //   "Last 30 Days": [moment().subtract(30, "d"), moment()],
        //   "This Month": [moment().startOf("month"), moment().endOf("month")],
        //   "Last month": [
        //     moment().subtract(1, "months").startOf("month"),
        //     moment().subtract(1, "months").endOf("month"),
        //   ],
        // }}
        defaultPickerValue={defaultPickerValue}
        disabledDate={disableFutureDates}
        onChange={(e) => handleSelectedDate(e)}
        format={"YYYY/MM/DD"}
      />
    </>
  );
};

export default RangePickerFilter;
