import * as React from "react";
import { useContext } from "react";
import "../../../assets/style/BS5_Grid.css";
import "../../../assets/style/style.css";
import { tenderResultService } from "../../../_services/tenderResultPageServices";
import InfiniteScroll from "react-infinite-scroll-component";
import BidderListModal from "../../../components/modal/BidderListModal";
import { selectedDateRange } from "../../../layouts/dashboard/header/context-api/Context";
import TenderArea from "../../../components/tender-area/TenderArea";
import Loader from "../../../components/loading-screen/Loader";
import { Skeleton } from "@mui/material";
import { valueConvert } from "../../../_helpers/valueConvert";

const MissedOpportunities = ({
  tenderForm,
  setDataFound,
  //   setFifthTabCount,
  page,
  setPage,
  isSplitWord,
  setIsSplitWord,
  isSearchBy,
  setIsSearchBy,
  setTenderForm,
  setRecordCount,
}) => {
  const { selectedFromDate, selectedToDate, isDateSelected } =
    useContext(selectedDateRange);

  const [freshResultData, setFreshResultData] = React.useState([]);
  const [freshResultHashMore, setFreshResultHasMore] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [modelData, setModelData] = React.useState([]);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [modalStage, setModalStage] = React.useState("");

  const fetchFreshResult = async () => {
    const nextFreshResult = await getfreshResult();
    if (nextFreshResult !== undefined && !isError) {
      nextFreshResult.length === 0 && freshResultData.length === 0
        ? setIsError(true)
        : nextFreshResult.length === 0 && freshResultData.length > 0
        ? setFreshResultHasMore(false)
        : setFreshResultHasMore(true);
      setFreshResultData([...freshResultData, ...nextFreshResult]);
    }
  };

  const getfreshResult = async () => {
    const res = await tenderResultService.getTenderResult({
      ...tenderForm,
      page_no: page,
      tab_id: 0,
      tender_status: 10,
      user_id: parseInt(localStorage.getItem("user_id")),
      user_query_id: parseInt(
        localStorage.getItem("user_email_service_query_id")
      ),
      bidder_id: parseInt(localStorage.getItem("bidder_id")),
    });
    if (res.Success) {
      setLoader(false);
      // setIsFilter(false);
      setIsError(false);
      setPage((prev) => prev + 1);
      res.TotalRecord < 20
        ? setFreshResultHasMore(false)
        : setFreshResultHasMore(true);

      //search by split word*********************//
      if (res.TotalRecord < 20) {
        if (
          tenderForm.search_text.split(" ").length > 1 &&
          !tenderForm.search_by_split_word
        ) {
          setFreshResultData([...freshResultData, ...res.Data]);
          setIsSplitWord(true);
          setPage(1);
          setTenderForm((prev) => ({ ...prev, search_by_split_word: true }));
          return;
        }
        //search by keyword and product id****************//
        if (
          tenderForm.search_by < 2 &&
          tenderForm.keyword_ids &&
          tenderForm.product_id &&
          !isSearchBy
        ) {
          setIsSearchBy(true);
          setPage(1);
          setFreshResultData([...freshResultData, ...res.Data]);
          if (freshResultData.length === 0) {
            setLoader(true);
          }

          return;
        }
      }
      //use return for fetchResult function  for page wise data
      return res.Data;
    } else {
      setLoader(false);
      return [];
    }
  };

  React.useEffect(() => {
    if (isSearchBy && tenderForm.search_by < 2) {
      setTenderForm((prev) => ({ ...prev, search_by: 2, page_no: 1 }));
    }
  }, [isSearchBy]);

  React.useEffect(() => {
    setPage(1);
    fetchFreshResult();
    if (!tenderForm.search_by_split_word) {
      if (!isSearchBy && !isSplitWord) {
        getCount();
        setLoader(true);
      }
    } else {
      setFreshResultHasMore(true);
    }
    setIsSearchBy(false);
  }, [tenderForm]);

  const openParticipatedBidder = (e, fresh_result) => {
    setModelOpen(true);
    setModalStage(fresh_result.stage.toLowerCase());
    tenderResultService
      .getParticipatingBidder({ result_id: fresh_result.result_id })
      .then((res) => {
        if (res.Success) {
          setModelData(
            res.Data.map((obj) => {
              return { ...obj, result_id: fresh_result.result_id };
            })
          );
        } else {
          setModelData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCount = async () => {
    const res = await tenderResultService.getCountService({
      ...tenderForm,
      publication_date_from: selectedFromDate,
      publication_date_to: selectedToDate,
      tender_status: 10,
      tab_id: 0,
      user_id: parseInt(localStorage.getItem("user_id")),
      user_query_id: parseInt(
        localStorage.getItem("user_email_service_query_id")
      ),
      bidder_id: parseInt(localStorage.getItem("bidder_id")),
      search_by: 1,
    });

    if (res.Success === true && res.TotalRecord > 0) {
      setRecordCount(res.Data);
    }
  };

  return (
    <div className="missed-opportunity-FreshResult-area">
      <div className="FreshResult">
        {isError === true ? (
          <h3> No Result Found as per your Filter Criteria.</h3>
        ) : loader ? (
          <Loader />
        ) : (
          <InfiniteScroll
            dataLength={freshResultData.length}
            hasMore={freshResultHashMore}
            next={fetchFreshResult}
            style={{ overflow: "unset" }}
            loader={
              // <LinerLoader />
              freshResultData.length > 19 && (
                <>
                  <Skeleton sx={{ bgcolor: "grey.300" }} />
                  <Skeleton sx={{ bgcolor: "grey.300" }} />
                  <Skeleton sx={{ bgcolor: "grey.300" }} />
                  <Skeleton sx={{ bgcolor: "grey.300" }} />
                </>
              )
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>No more record.</b>
              </p>
            }
            // scrollableTarget="scroll"
          >
            <TenderArea
              tenderResultData={freshResultData}
              openParticipatedBidder={openParticipatedBidder}
              setDataFound={setDataFound}
              fromFavorite={true}
              tenderForm={tenderForm}
              setPage={setPage}
            />
          </InfiniteScroll>
        )}

        <BidderListModal
          modelOpen={modelOpen}
          setModelOpen={setModelOpen}
          modelData={modelData}
          modalStage={modalStage}
        />
      </div>
    </div>
  );
};

export default MissedOpportunities;
