import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useContext,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession } from "./utils";
import { http } from "../_apiConfig/http";
import {
  fromDate,
  fromDefaulDate,
  toDate,
  toDefaultDate,
  defaultYear,
} from "../components/date-input/FromDateToDate";
import { selectedDateRange } from "../layouts/dashboard/header/context-api/Context";
import { setResultListingModel, setSelectedListing } from "../redux/slice";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const logoutDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();
  const {
    setSelectedFromDate,
    setIsDateChange,
    setIsYearChange,
    setIsDateSelected,
    setSelectedToDate,
  } = useContext(selectedDateRange);

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const { user } = [];

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password, ip) => {
    setSelectedFromDate(fromDate());
    setSelectedToDate(toDate());
    setIsDateChange(false);
    setIsYearChange(false);
    setIsDateSelected(false);
    localStorage.removeItem("dashboardStatics");
    localStorage.clear();
    sessionStorage.clear();
    const response = await http.post(
      "/T247ApiAnalytics/api/user-login",
      {
        email_id: email,
        password: password,
        ip_address: "",
      },
      false
    );
    const {
      token,
      company_name,
      user_id,
      user_email_service_query_id,
      bidder_id,
    } = response.Data;

    localStorage.setItem("user_name", company_name);
    localStorage.setItem("bidder_id", bidder_id);
    localStorage.setItem(
      "user_email_service_query_id",
      user_email_service_query_id
    );
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("from_date", fromDefaulDate()); //
    localStorage.setItem("to_date", toDefaultDate()); //
    localStorage.setItem("selectedYear", defaultYear()); //
    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        company_name,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;
    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    // logoutDispatch(setResultListingModel(null));
    // logoutDispatch(setSelectedListing(null));
    // setSession(null);
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("dashboardStatics");
    // localStorage.removeItem("user_name");
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("user_email_service_query_id");
    // localStorage.removeItem("selectedYear");
    // localStorage.removeItem("from_date");
    // localStorage.removeItem("to_date");
    // localStorage.removeItem("bidder_id");
    // dispatch({
    //   type: "LOGOUT",
    // });
    // localStorage.clear();
    // sessionStorage.clear();
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
