import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import { bgBlur } from "../../../utils/cssStyles";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import { HEADER, NAV } from "../../../config-global";
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
import AccountPopover from "./AccountPopover";
import ResultDateInput from "./components/ResultDateInput";
import { useDispatch, useSelector } from "react-redux";
import { selectedDateRange } from "./context-api/Context";
import { useContext } from "react";

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const urlPath = window.location.href.split("/")[4];
  const dispatch = useDispatch();
  const {
    selectedFromDate,
    selectedToDate,
    isDateSelected,
    setIsDateSelected,
  } = useContext(selectedDateRange);
  // const [fromDashboard, setFromDashoard] = useState(true)
  const initialListing = useSelector(
    (state) => state.listing_model.initialListing
  );
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  // useEffect(() => {
  //   dispatch(
  //     setSelectedListing({
  //       ...initialListing,
  //       publication_date_from: selectedFromDate,
  //       publication_date_to: selectedToDate
  //     })
  //   );
  //   setFromDashoard(false)
  // }, [window.location.href])

  const getPageTitle = () => {
    const path = window.location.href.split("/")[4];
    const queryPath = window.location.href.split("?")[0].split("/")[4];
    switch (path) {
      case "home":
        return "Dashboard";
      case "competitors":
        return "Competitors";
      case "company-profile":
        return "Company Profile";
      case "tender-result":
        return "Tender Result";
      case "comparision":
        return "Companies Compare";
      case "listingdetails":
        return "Tender Detail";
      case "profile":
        return "Profile";
      case "misreport":
        return "MIS Reports";
      case "missedOpportunity":
        return "Missed Opportunity";
      case "mail-result":
        return "Mail Result";
      default:
        if (queryPath === "company-profile") {
          return "Company Profile";
        } else if (queryPath === "tender-result") {
          return "Tender Result";
        } else if (queryPath === "companyprofile-tenders") {
          return "Company Profile Tenders";
        } else {
          return "";
        }
    }
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <div className="header-title">
        <h2 style={{ color: "black" }}>{"Company Profile"}</h2>
      </div>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {urlPath === "mail-result" ? (
          ""
        ) : (
          <div className="rangepicker-area">
            <ResultDateInput
              // fromDashboard={
              //   window.location.href.split("/")[4] === "home" ||
              //     window.location.href.split("/")[4] === "competitors" ||
              //     window.location.href.split("/")[4] === "listingdetails"
              //     ? true
              //     : false
              // }
              fromDashboard={
                window.location.href.split("/")[4] === "home" ? true : false
              }
            />
          </div>
        )}
        {/* <NotificationsPopover /> */}
        {/* <AccountPopover /> */}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
