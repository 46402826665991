import { TextField } from "@mui/material";
import React from "react";

const WebSiteType = (props) => {
  const { websiteType, setWebsiteType } = props;
  return (
    <div className="state-area">
      <TextField
        label={"Website"}
        value={websiteType}
        variant="outlined"
        onChange={(e) => {
          setWebsiteType(e.target.value);
        }}
      />
    </div>
  );
};

export default WebSiteType;
