import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Grid, Tab } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { useSettingsContext } from "../../../components/settings/SettingsContext";
import DowloadExcelBtn from "../../../components/download-tender-excel/DowloadExcelBtn";
import TenderSorting from "../../../components/tenderSortSelect/TenderSorting";
import { valueConvert } from "../../../_helpers/valueConvert";
import MailTenderResults from "../MailResultTabs/MailTenderResults";
import MailBidders from "../MailResultTabs/MailBidders";
import DownloadMailResult from "../DownloadMailResult";

const MailResultTabArea = (props) => {
  const StyledTab = styled(Tab)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  }));

  const {
    value,
    setValue,
    dataFound,
    setDataFound,
    tenderForm,
    setTenderForm,
    setIsFilter,
    page,
    setPage,
    tenderResultTabChange,
    mailTenderTabCount,
    setMailTenderTabCount,
    mailBidderTabCount,
    setMailBidderTabCount,
  } = props;

  const { themeStretch } = useSettingsContext();

  const handleChange = (event, newValue) => {
    tenderResultTabChange(newValue);
  };

  return (
    <div className="tab-area">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={3}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <StyledTab
                    className="listing-tabs"
                    label={`${"Tender Results"} ${
                      mailTenderTabCount?.resultcount !== undefined &&
                      mailTenderTabCount?.resultcount > 0 &&
                      value === "1"
                        ? `(${valueConvert(mailTenderTabCount?.resultcount)})`
                        : ""
                    }`}
                    value="1"
                  />
                  <StyledTab
                    className="listing-tabs"
                    label={`${"Bidders"} ${
                      mailBidderTabCount?.resultcount !== undefined &&
                      mailBidderTabCount?.resultcount > 0 &&
                      value === "2"
                        ? `(${valueConvert(mailBidderTabCount?.resultcount)})`
                        : ""
                    }`}
                    value="2"
                  />
                </TabList>

                <div className="sort-by-main-area">
                  {value === "1" && (
                    <>
                      <DownloadMailResult
                        tenderForm={tenderForm}
                        value={value}
                        mailTenderTabCount={mailTenderTabCount}
                      />
                      <TenderSorting
                        setDataFound={setDataFound}
                        setIsFilter={setIsFilter}
                        setTenderForm={setTenderForm}
                        setPage={setPage}
                      />
                    </>
                  )}
                </div>
              </Box>
              <TabPanel value="1">
                {/* {dataFound && ( */}
                <MailTenderResults
                  tenderForm={tenderForm}
                  setDataFound={setDataFound}
                  setTenderForm={setTenderForm}
                  setMailTenderTabCount={setMailTenderTabCount}
                  page={page}
                  setPage={setPage}
                />
                {/* )} */}
              </TabPanel>
              <TabPanel value="2">
                {/* {dataFound && ( */}
                <MailBidders
                  tenderForm={tenderForm}
                  setDataFound={setDataFound}
                  setTenderForm={setTenderForm}
                  setMailBidderTabCount={setMailBidderTabCount}
                  page={page}
                  setPage={setPage}
                />
                {/* )} */}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default MailResultTabArea;
