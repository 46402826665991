import React from "react";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { tenderDetailServices } from "../../_services/tenderDetailServices";
import { useTheme } from "@mui/material/styles";
import api from "../../_apiConfig/baseapi";
import Loading from "../../assets/images/loading-gif.gif";
import { Tooltip } from "@mui/material";
import { errorNotify } from "../../common/CommonUtils/CommonUtils";

const DownloadDoc = (resultId) => {
  const theme = useTheme();
  const [loader, setLoader] = React.useState(false);

  // const handleDownloadAllDocuments = (e, result_Id) => {
  //   tenderDetailServices
  //     .tenderDetail({
  //       result_id: result_Id,
  //     })
  //     .then((res) => {
  //       if (res.Success === true) {
  //         const docPath = res?.Data[0]?.document_path;
  //         tenderDetailServices.downloadAllDocument(docPath).then((res) => {
  //           if (res.Success === false) {
  //             alert("No Document Found");
  //             return;
  //           }
  //           window.location.href = `${api.defaults.DonwloadDoc}/T247ApiAnalytics/api/download-for-zip?document_path=${docPath}`;
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Tender Detail Error" + err);
  //     });
  // };

  const handleDownloadAllDocuments = async (e, result_Id) => {
    try {
      setLoader(true);
      const detailRes = await tenderDetailServices.tenderDetail({
        result_id: result_Id,
      });
      if (detailRes.Success) {
        const docPath = detailRes?.Data[0]?.document_path;

        // Fetch the document as a blob
        const response = await fetch(
          `${api.defaults.DonwloadDoc}/T247ApiAnalytics/api/download-for-zip?document_path=${docPath}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/zip", // Adjust if needed
              // Add other necessary headers here
            },
          }
        );

        if (!response.ok) {
          alert("Document server is under maintenance, Please Try after sometime");
          throw new Error("Network response was not ok");
          return;
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Tender_${result_Id}.zip`; // Set the desired file name here
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Error in Tender Detail Api", result_Id);
      }
    } catch (err) {
      console.error("Error:", err);
      errorNotify("Document server is under maintenance, Please Try after sometime");
      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <div className="tender-area-documents-download-btn-loader">
          <img src={Loading} />
        </div>
      ) : (
        <a
          target="_blank"
          href="https://tender247.com/car-registration"
          style={{ cursor: "pointer" }}
          // onClick={(e) => handleDownloadAllDocuments(e, resultId.resultId)}
        >
          <Tooltip title="Download Tender Documents" placement="bottom">
            <SimCardDownloadIcon
              style={{
                color: theme.palette.primary.main,
              }}
              className="listing-icon"
            />
          </Tooltip>
        </a>
      )}
    </>
  );
};

export default DownloadDoc;
