import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { dowloadExcel } from "../../_services/downloadExcel";
import api from "../../_apiConfig/baseapi";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { todayDate } from "../date-input/FromDateToDate";
import Loading from "../../assets/images/loading-gif.gif";
import { Tooltip } from "@mui/material";

const DowloadExcelBtn = (props) => {
  const { tenderForm, value, pageName } = props;
  const theme = useTheme();
  const [loader, setLoader] = useState(false);

  const handleExcelDownload = async () => {
    const getStatusBasedForm = (status) => ({
      ...tenderForm,
      search_by:
        tenderForm.keyword_ids > 0 && tenderForm.product_id > 0
          ? tenderForm.search_by
          : 0,
      tender_status: status,
    });

    // const downloadExcelForKey = async (downloadParams) => {
    //   const res = await dowloadExcel.getDownloadExcelKey(downloadParams);
    //   if (res.Success) {
    //     window.location.href = `${api.defaults.DonwloadDoc}/T247ApiAnalytics/api/export-excel/${res?.Data}`;
    //   } else {
    //     alert("No Data found");
    //   }
    // };

    const downloadExcelForKey = async (downloadParams) => {
      try {
        setLoader(true);
        const res = await dowloadExcel.getDownloadExcelKey(downloadParams);
        if (res.Success) {
          const response = await fetch(
            `${api.defaults.DonwloadDoc}/T247ApiAnalytics/api/export-excel/${res?.Data}`,
            {
              method: "GET",
              headers: {
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Results-(${todayDate()}).xlsx`; // Replace with your preferred file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          setLoader(false);
        } else {
          alert("No Data found");
          setLoader(false);
        }
      } catch (error) {
        console.error("Error during download:", error);
        alert("An error occurred while downloading the file.");
        setLoader(false);
      }
    };

    let downloadParams = tenderForm;
    if (pageName === "tenderListing") {
      if (value === "1") {
        downloadParams = getStatusBasedForm(3);
      } else if (value === "2") {
        downloadParams = getStatusBasedForm(0);
      } else if (value === "3") {
        downloadParams = {
          ...getStatusBasedForm(5),
          bidder_name: localStorage.getItem("user_name"),
        };
      } else if (value === "4") {
        downloadParams = getStatusBasedForm(4);
      } else if (value === "5") {
        downloadParams = getStatusBasedForm(10);
      }
    } else {
      if (value === "1") {
        downloadParams = getStatusBasedForm(7);
      } else if (value === "2") {
        downloadParams = getStatusBasedForm(6);
      } else if (value === "3") {
        downloadParams = getStatusBasedForm(1);
      } else if (value === "4") {
        downloadParams = getStatusBasedForm(2);
      }
    }
    await downloadExcelForKey(downloadParams);
  };

  return (
    <>
      {loader ? (
        <div className="xlsx-btn-loader">
          <img src={Loading} />
        </div>
      ) : (
        <div
          className="location-area bidder-area"
          onClick={handleExcelDownload}
          style={{
            borderColor: theme.palette.primary.darker,
            color: theme.palette.primary.darker,
          }}
        >
          <Tooltip title="Download Excel" placement="left">
            <SimCardDownloadIcon
              style={{
                color: theme.palette.primary.darker,
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DowloadExcelBtn;
