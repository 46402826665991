import { TextField } from "@mui/material";
import React from "react";

const SearchBar = (props) => {
  const { wordSearch, setWordSearch, fromPage, isListingPage } = props;
  return (
    <div className={fromPage !== "comparePage" ? "state-area" : ""}>
      <TextField
        label={isListingPage ? "Word Search / Result Id" : "Word Search"}
        value={wordSearch}
        variant="outlined"
        onChange={(e) => {
          setWordSearch(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchBar;
