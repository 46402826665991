// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: icon("ic_dashboard"),
  banking: icon("ic_banking"),
  user: icon("ic_user"),
  booking: icon("ic_booking"),
  misReportIcon: icon("ic_folder"),
  analytics: icon("ic_analytics"),
  missedOppIcon: icon("ic_external"),
  mailResultIcon: icon("ic_file"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v4.2.0',
    items: [
      {
        title: "Dashboard",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.dashboard,
      },
      {
        title: "Competitors",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.banking,
      },
      {
        title: "Company Profile",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.user,
      },
      {
        title: "Tender Result",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.booking,
      },
      {
        title: "MIS Reports",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.misReportIcon,
      },
      {
        title: "Compare Bidders",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.analytics,
      },
      {
        title: "Missed Opportunity",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.missedOppIcon,
      },
      {
        title: "Mail Result",
        path: 'https://tender247.com/car-registration',
        icon: ICONS.mailResultIcon,
      },
    ],
  },
];

export default navConfig;
