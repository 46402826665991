import { Helmet } from "react-helmet-async";
import Login from "./login/Index";
import React, { useEffect } from "react";
import { loginService } from "../_services/loginService";
import {
  fromDefaulDate,
  toDefaultDate,
} from "../components/date-input/FromDateToDate";
import { useNavigate, useParams } from "react-router";
import { setSession } from "../auth/utils";
import { useDispatch, useSelector } from "react-redux";
import { setListingModel, setResultListingModel, setSelectedListing } from "../redux/slice";
import { useState } from "react";
import Loader from "../components/loading-screen/Loader";
import moment from "moment";
// sections
// import Login from '../sections/auth/Login';
// import Login from '../../sections/auth/LoginAuth0';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const topCompetitorsTableListing = useSelector(
    (state) => state.listing_model.initialListing
  );
  const dispatch = useDispatch();
  const [tenderForm, setTenderForm] = useState({
    ...topCompetitorsTableListing,
  });
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const autologinkey = urlParams.get("key");

  const getAutoLogin = async () => {
    // console.log(autologinkey, "autologinkey");
    let mailDate = moment().format("YYYY-MM-DD");

    if (autologinkey !== null && autologinkey.length > 0) {
      const res = await loginService.autoLogin(autologinkey);
      if (res.Success === true) {
        const {
          user_email_service_query_id,
          user_id,
          token,
          user_name,
          redirection_page_id,
          participated_bidder_name,
          company_name,
          bidder_id,
          mail_date,
        } = res.Data;

        localStorage.setItem("user_name", company_name);
        localStorage.setItem(
          "user_email_service_query_id",
          user_email_service_query_id
        );
        localStorage.setItem("bidder_id", bidder_id);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("from_date", fromDefaulDate());
        localStorage.setItem("to_date", toDefaultDate());
        setSession(token);
        if(mail_date){
          mailDate = mail_date;
        }

        if (token.length > 0 && redirection_page_id === 1) {
          dispatch(
            setResultListingModel({
              ...tenderForm,
              tender_status: 0,
              bidder_name: "",
              user_id: user_id,
              user_query_id: user_email_service_query_id,
              publication_date_from: fromDefaulDate(),
              publication_date_to: toDefaultDate(),
              mail_date: mailDate,
            })
          );
          navigate("/dashboard/tender-result");
        } else if (
          token.length > 0 &&
          user_name.length > 0
          && redirection_page_id > 1 && redirection_page_id <= 7
        ) {
          const tenderStatus = redirection_page_id==6?6:
          redirection_page_id==7?1:
          redirection_page_id==2?7:
          2
          const stage = redirection_page_id==4?[{
            "stage_id": 1,
            "stage": "Technical"
        }]:redirection_page_id==5?[{
          "stage_id": 2,
          "stage": "Financial"
      }]:[]

          dispatch(
            setSelectedListing({
              ...tenderForm,
              stage:
                stage
              ,  
              tender_status:tenderStatus,
              bidder_name: participated_bidder_name,
              user_id: user_id,
              user_query_id: user_email_service_query_id,
              publication_date_from: fromDefaulDate(),
              publication_date_to: toDefaultDate(),
              mail_date: mailDate,
            })
          );
        
          // console.log()
          navigate("/dashboard/companyprofile-tenders");
        } else if (token.length > 0 && redirection_page_id >= 8 && redirection_page_id <= 12) {

          const tenderStatus = redirection_page_id === 8 ? 7 :
          redirection_page_id === 9 ? 6 :
          redirection_page_id === 10 ? 1 : 
          redirection_page_id === 11 ? 2 : 
          redirection_page_id === 12 ? 2 : 7

          const stage = redirection_page_id === 11 ? [{
            "stage_id": 2,
            "stage": "Financial"
          }]:redirection_page_id === 12 ? [{
            "stage_id": 1,
            "stage": "Technical"
          }] : []

          dispatch(
            setSelectedListing({
              ...tenderForm,
              stage: stage,
              tender_status: tenderStatus,
              bidder_name: participated_bidder_name,
              user_id: user_id,
              user_query_id: user_email_service_query_id,
              publication_date_from: fromDefaulDate(),
              publication_date_to: toDefaultDate(),
              mail_date: mailDate,
            })
          );
        
          navigate("/dashboard/companyprofile-tenders");
        } else if (token.length > 0 && redirection_page_id === 13) {
          dispatch(
            setResultListingModel({
              ...tenderForm,
              user_id: user_id,
              user_query_id: user_email_service_query_id,
              publication_date_from: fromDefaulDate(),
              publication_date_to: toDefaultDate(),
              mail_date: mailDate,
            })
          );
          navigate("/dashboard/competitors");
        }
      } else {
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_email_service_query_id");
        localStorage.removeItem("user_id");
        localStorage.removeItem("from_date");
        localStorage.removeItem("to_date");
        localStorage.removeItem("selectedYear");
        localStorage.removeItem("bidder_id");
      }
    }
  };

  useEffect(() => {
    if (autologinkey !== undefined) {
      sessionStorage.removeItem("bidModel")
      getAutoLogin();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Login | Minimal UI</title>
      </Helmet>
      {isError ? <Loader /> : <Login />}
      {/* <Login /> */}
    </>
  );
}
