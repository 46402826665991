import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { dowloadExcel } from "../../_services/downloadExcel";
import api from "../../_apiConfig/baseapi";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { todayDate } from "../../components/date-input/FromDateToDate";
import Loading from "../../assets/images/loading-gif.gif";
import { Tooltip } from "@mui/material";
import { tenderMailResultService } from "../../_services/tenderMailResultService";
import { errorNotify, infoNotify } from "../../common/CommonUtils/CommonUtils";
const DownloadMailResult = (props) => {
  const { tenderForm, value, mailTenderTabCount } = props;
  const theme = useTheme();
  const [loader, setLoader] = useState(false);

  const handleExcelDownload = async () => {
    const downloadExcelForKey = async () => {
      try {
        setLoader(true);
        const res = await tenderMailResultService.getMailResultDownloadKey(
          tenderForm
        );
        if (res.Success) {
          const response = await fetch(
            `${api.defaults.DonwloadDoc}/T247ApiAnalytics/api/export-excel/${res?.Data}`,
            {
              method: "GET",
              headers: {
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Results-(${todayDate()}).xlsx`; // Replace with your preferred file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          setLoader(false);
        } else {
          infoNotify("No Data found");
          setLoader(false);
        }
      } catch (error) {
        console.error("Error during download:", error);
        errorNotify("An error occurred while downloading the file.");
        setLoader(false);
      }
    };

    if (
      mailTenderTabCount?.resultcount &&
      mailTenderTabCount?.resultcount > 0
    ) {
      await downloadExcelForKey();
    } else {
      infoNotify("No Result Found as per your Filter Criteria.");
    }
  };

  return (
    <>
      {loader ? (
        <div className="xlsx-btn-loader">
          <img src={Loading} />
        </div>
      ) : (
        <div
          className="location-area bidder-area"
          onClick={handleExcelDownload}
          style={{
            borderColor: theme.palette.primary.darker,
            color: theme.palette.primary.darker,
          }}
        >
          <Tooltip title="Download Excel" placement="left">
            <SimCardDownloadIcon
              style={{
                color: theme.palette.primary.darker,
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DownloadMailResult;
