import { http } from "../_apiConfig/http";
import { RouteUrls } from "../_apiConfig/routeUrls";
export const tenderMailResultService = {
  getTenderMailResult,
  getTenderMailResultCount,
  getMailBiddersResult,
  getMailBiddersCount,
  getMailResultDownloadKey,
};

function getTenderMailResult(data) {
  return http.post(RouteUrls.tenderMailResults, data, true);
}

function getTenderMailResultCount(data) {
  return http.post(RouteUrls.tenderMailResultCount, data, true);
}

function getMailBiddersResult(data) {
  return http.post(RouteUrls.tenderMailBidders, data, true);
}

function getMailBiddersCount(data) {
  return http.post(RouteUrls.tenderMailBiddersCount, data, true);
}

function getMailResultDownloadKey(data) {
  return http.post(
    RouteUrls.tenderMailResultDownload,
    {
      ...data,
      user_id: parseInt(localStorage.getItem("user_id")),
      user_query_id: parseInt(
        localStorage.getItem("user_email_service_query_id")
      ),
    },
    true
  );
}
