import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { compititorServices } from "../../../_services/compititorService";
import { Button, IconButton, Skeleton, Snackbar, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { selectedDateRange } from "../../../layouts/dashboard/header/context-api/Context";
import { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedListing, setBidderName } from "../../../redux/slice";
import { valueConvert } from "../../../_helpers/valueConvert";
import { truncateName } from "../../../_helpers/truncateName";
import { TableRowsLoader } from "../../../components/table-loader/TableLoader";
import { object } from "yup";
import { Try } from "@mui/icons-material";
import { tenderMailResultService } from "../../../_services/tenderMailResultService";
import { tenderResultService } from "../../../_services/tenderResultPageServices";

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial !important",
  },
});

const sort = [
  {
    id: "bidder_name",
    key: 1,
    numeric: false,
    disablePadding: true,
    label: "#. Company Name",
    active: false,
    ascending: true,
  },
  {
    id: "participated_tenders",
    key: 2,
    numeric: false,
    disablePadding: true,
    active: true,
    label: "Participated Tender",
    ascending: false,
  },
  {
    id: "awarded_tender",
    numeric: false,
    key: 3,
    active: false,
    disablePadding: true,
    label: "Awarded Tender",
    ascending: true,
  },
  {
    id: "lost_tender",
    key: 4,
    numeric: false,
    active: false,
    disablePadding: true,
    label: "Lost Tender",
    ascending: true,
  },
  {
    id: "result_to_be_announced",
    numeric: false,
    key: 5,
    disablePadding: true,
    active: false,
    label: "Result TBA",
    ascending: true,
  },
  // {
  //   id: "Shortlist_Competitor",
  //   numeric: false,
  //   key: 6,
  //   disablePadding: true,
  //   active: false,
  //   label: "Shortlist Competitor",
  //   ascending: true,
  // },
];

const MailBidders = ({
  tenderForm,
  setDataFound,
  page,
  setPage,
  setMailBidderTabCount,
  setTenderForm,
}) => {
  const generalCompetitorListing = useSelector(
    (state) => state.listing_model.initialListing
  );
  const {
    selectedFromDate,
    selectedToDate,
    isDateSelected,
    setIsDateSelected,
  } = useContext(selectedDateRange);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sessionData = sessionStorage.getItem("bidModel");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [headCells, setHeadCells] = useState(sort);
  const [tenderResultData, setTenderResultData] = React.useState([]);
  const [tenderResultHasMore, setTenderResultHasMore] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const initialListing = useSelector(
    (state) => state.listing_model.initialListing
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.darker,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    setPage(1);
    setLoader(true);
    mailTenderData();
    getCount();
  }, [tenderForm]);

  const mailTenderData = async () => {
    try {
      const res = await tenderMailResultService.getMailBiddersResult({
        ...tenderForm,
        page_no: page,
        user_id: parseInt(localStorage.getItem("user_id")),
        user_query_id: parseInt(
          localStorage.getItem("user_email_service_query_id")
        ),
      });
      setLoader(false);
      if (res.Success) {
        setLoader(false);
        setPage((prev) => prev + 1);

        if (page === 1) {
          setTenderResultData(res.Data);
        } else {
          setTenderResultData((prev) => [...prev, ...res.Data]);
        }
        if (res.TotalRecord < 20) {
          setTenderResultHasMore(false);
        } else {
          setTenderResultHasMore(true);
        }
      } else {
        setLoader(false);
        setPage((prev) => prev + 0);
        setTenderResultHasMore(false);
        setTenderResultData((prev) => [...prev, ...[]]);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      setTenderResultHasMore(false);
      setTenderResultData((prev) => [...prev, ...[]]);
      setPage((prev) => prev + 0);
    }
  };

  const getCount = async () => {
    const res = await tenderMailResultService.getMailBiddersCount({
      ...tenderForm,
      page_no: page,
      user_id: parseInt(localStorage.getItem("user_id")),
      user_query_id: parseInt(
        localStorage.getItem("user_email_service_query_id")
      ),
    });

    if (res.Success && res.TotalRecord > 0) {
      setMailBidderTabCount(res.Data);
    }
  };

  const handleShorting = (e, res) => {
    const element = document.getElementById("scroll");
    element.scrollTop = 0;
    const data = headCells.map((cell) => {
      if (cell.key === res.key) {
        return { ...cell, ascending: !cell.ascending, active: true };
      } else {
        return { ...cell, ascending: true, active: false };
      }
    });
    setHeadCells(data);
    setPage(1);
    setTenderForm((prev) => ({
      ...prev,
      sort_by: res.key,
      sort_type: res.ascending ? 2 : 1,
      page_no: 1,
    }));
  };

  const handleInsert = (e, competitorData) => {
    const data = tenderResultData.map((res) => {
      if (competitorData.bidder_name === res.bidder_name) {
        return { ...res, is_check: e.target.checked };
      } else {
        return res;
      }
    });
    setTenderResultData(data);
    compititorServices
      .insert({
        user_id: localStorage.getItem("user_id"),
        bidder_id: 0,
        bidder_name: competitorData.bidder_name,
      })
      .then((competitorData) => {
        if (competitorData.Success) {
          setCheckMainData(true);
        } else {
          setCheckMainData(false);
        }
      })
      .catch((err) => {
        console.log("Dashboard tender State vise" + err);
      });
  };

  const handleUnCheck = (e, competitorData) => {
    const data = tenderResultData.map((res) => {
      if (competitorData.bidder_name === res.bidder_name) {
        return { ...res, is_check: e.target.checked };
      } else {
        return res;
      }
    });

    setTenderResultData(data);
    compititorServices
      .deleteData({
        bidder_id: 0,
        bidder_name: competitorData.bidder_name,
      })
      .then((res) => {
        if (res.Success) {
          setCheckMainData(true);
        } else {
          setValue([]);
          setCheckMainData(false);
        }
      })
      .catch((err) => {
        console.log("Dashboard tender State vise" + err);
      });
  };

  const handleAwardedTenders = (e, competitor) => {
    if (
      generalCompetitorListing.bidder_name !== null &&
      generalCompetitorListing.tender_status !== null
    ) {
      dispatch(
        setSelectedListing({
          ...tenderForm,
          bidder_name: competitor.bidder_name,
          tender_status: 6,
        })
      );
      window.open(
        `/dashboard/companyprofile-tenders?datefrom=${selectedFromDate}&dateto=${selectedToDate}`
      );
    }
  };

  const redirectToCompanyProfile = (e, competitor) => {
    dispatch(setBidderName(competitor.bidder_name));
    window.open(
      `/dashboard/company-profile?datefrom=${selectedFromDate}&dateto=${selectedToDate}`
    );
  };

  const handleMyParticipated = (e, competitor) => {
    if (
      generalCompetitorListing.bidder_name !== null &&
      generalCompetitorListing.tender_status !== null
    ) {
      dispatch(
        setSelectedListing({
          ...tenderForm,
          bidder_name: competitor.bidder_name,
          tender_status: 7,
        })
      );
      window.open(
        `/dashboard/companyprofile-tenders?datefrom=${selectedFromDate}&dateto=${selectedToDate}`
      );
    }
  };

  const handleLostTenders = (e, competitor) => {
    if (
      generalCompetitorListing.bidder_name !== null &&
      generalCompetitorListing.ternder_status !== null
    ) {
      dispatch(
        setSelectedListing({
          ...tenderForm,
          bidder_name: competitor.bidder_name,
          tender_status: 1,
        })
      );
      window.open(
        `/dashboard/companyprofile-tenders?datefrom=${selectedFromDate}&dateto=${selectedToDate}`
      );
    }
  };

  const handleResultTBA = (e, competitor) => {
    if (
      generalCompetitorListing.bidder_name !== null &&
      generalCompetitorListing.tender_status !== null
    ) {
      dispatch(
        setSelectedListing({
          ...tenderForm,
          bidder_name: competitor.bidder_name,
          tender_status: 2,
        })
      );
      window.open(
        `/dashboard/companyprofile-tenders?datefrom=${selectedFromDate}&dateto=${selectedToDate}`
      );
    }
  };

  const handleClickEvent = (e, key, res) => {
    const storeMailDate = () => {
      dispatch(
        setSelectedListing({
          ...initialListing,
          mail_date: tenderForm.mail_date,
        })
      );
    };
    if (sessionData !== null) {
      sessionStorage.removeItem("bidModel");
    }
    switch (key) {
      case "awardvalue":
        storeMailDate();
        handleAwardedTenders(e, res);
        break;
      case "companyprofile":
        redirectToCompanyProfile(e, res);
        break;
      case "participated":
        storeMailDate();
        handleMyParticipated(e, res);
        break;
      case "losttender":
        storeMailDate();
        handleLostTenders(e, res);
        break;
      case "rtba":
        storeMailDate();
        handleResultTBA(e, res);
        break;
      default:
        break;
    }
  };

  return (
    <div className="col-12">
      <div className="filter-area">
        <div>
          <InfiniteScroll
            dataLength={tenderResultData.length}
            hasMore={tenderResultHasMore}
            next={mailTenderData}
            scrollableTarget="scroll"
          >
            <div className="top-competitors general-competitors" id="scroll">
              <TableContainer
                classes={{ root: classes.customTableContainer }}
                component={Paper}
              >
                <Table aria-label="customized table">
                  <TableHead className="publish-tender-in-state-area">
                    <TableRow>
                      {headCells.map((res) => {
                        return (
                          <StyledTableCell>
                            {res.key === 6 ? (
                              <>
                                <span>{res.label}</span>
                              </>
                            ) : (
                              <>
                                <span
                                  className={`${
                                    res.active ? "short_active" : ""
                                  }`}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleShorting(e, res)}
                                >
                                  {res.label}
                                </span>

                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleShorting(e, res)}
                                >
                                  {res.ascending ? (
                                    <ArrowUpwardIcon />
                                  ) : (
                                    <ArrowDownwardIcon />
                                  )}
                                </span>
                              </>
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loader ? (
                      <TableRowsLoader rowsNum={10} colunmNum={5} />
                    ) : tenderResultData.length > 0 ? (
                      tenderResultData.map((res, key) => {
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">
                              <span
                                onClick={(e) =>
                                  handleClickEvent(e, "companyprofile", res)
                                }
                              >
                                <Tooltip title={res?.bidder_name}>
                                  <span>{`${key + 1}) ${truncateName(
                                    res?.bidder_name
                                  )}`}</span>
                                </Tooltip>
                              </span>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              {res?.participated_tenders === 0 ? (
                                0
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClickEvent(e, "participated", res)
                                  }
                                >
                                  {res?.participated_tenders}
                                </span>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              {res?.awarded_tender === 0 ? (
                                0
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClickEvent(e, "awardvalue", res)
                                  }
                                >
                                  {res?.awarded_tender}
                                </span>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              {res?.lost_tender === 0 ? (
                                0
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClickEvent(e, "losttender", res)
                                  }
                                >
                                  {res?.lost_tender}
                                </span>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              {res?.result_to_be_announced === 0 ? (
                                0
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClickEvent(e, "rtba", res)
                                  }
                                >
                                  {res?.result_to_be_announced}
                                </span>
                              )}
                            </StyledTableCell>
                            {/* <StyledTableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              <Checkbox
                                checked={res.is_check}
                                onClick={
                                  res.is_check === false
                                    ? (e) => handleInsert(e, res)
                                    : (e) => handleUnCheck(e, res)
                                }
                                {...label}
                              />
                            </StyledTableCell> */}
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <h3 className="data-not-found">
                        No Competitors Found For The Selected Date
                      </h3>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {tenderResultHasMore && (
                <>
                  {" "}
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default MailBidders;
