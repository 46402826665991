import React, { useEffect } from "react";
import { tenderResultFilterService } from "../../_services/tenderResultFilterServices";
import { useState } from "react";
import { Autocomplete, FormControl, TextField } from "@mui/material";

const CitySingleSelect = (props) => {
  const { stateTag, setCityTag, cityTag, fromPage } = props;
  const [cityData, setCityData] = useState([]);
  const [isError, setIsError] = useState(false);

  const getFilterCity = async () => {
    const res = await tenderResultFilterService.getCity({
      id: 0,
      parentids: stateTag.state_id,
      pageNo: 0,
      noofrecords: 0,
      name: "",
    });

    if (res.Success && res.TotalRecord > 0) {
      setIsError(false);
      setCityData(
        res.Data.map((res) => ({ ...res, title: res.city_name })).sort(
          (a, b) => {
            if (a.city_name < b.city_name) {
              return -1;
            }
            if (a.city_name > b.city_name) {
              return 1;
            }
            return 0;
          }
        )
      );
    } else {
      setIsError(true);
      setCityData([]);
    }
  };

  // const filterOptions = (options, state) => {
  //   return options.filter((res) =>
  //     res?.state_name?.toLowerCase().startsWith(state.inputValue.toLowerCase())
  //   );
  // };

  const handleChange = (event, value) => {
    event.preventDefault();
    value && value !== null
      ? setCityTag({ ...value, title: value.city_name, type: "city" })
      : setCityTag(null);
  };
  useEffect(() => {
    stateTag !== undefined && stateTag !== null
      ? getFilterCity()
      : setCityTag(null);
  }, [stateTag]);

  return stateTag !== undefined && stateTag !== null ? (
    <div className={fromPage !== "comparision" ? "state-area" : ""}>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          options={
            cityData !== undefined && cityData.length > 0 ? cityData : []
          }
          id="demo-simple-select"
          value={cityTag}
          onChange={handleChange}
          // filterOptions={filterOptions}
          getOptionLabel={(option) => option.city_name}
          renderInput={(params) => (
            <TextField {...params} label="Select city" />
          )}
        />
      </FormControl>
    </div>
  ) : (
    <></>
  );
};

export default CitySingleSelect;
