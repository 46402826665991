import * as React from "react";
import "../../../assets/style/BS5_Grid.css";
import "../../../assets/style/style.css";
import { tenderResultService } from "../../../_services/tenderResultPageServices";
import InfiniteScroll from "react-infinite-scroll-component";
import BidderListModal from "../../../components/modal/BidderListModal";
import { selectedDateRange } from "../../../layouts/dashboard/header/context-api/Context";
import { useContext } from "react";
import TenderArea from "../../../components/tender-area/TenderArea";
import Loader from "../../../components/loading-screen/Loader";
import { Skeleton } from "@mui/material";
import { tenderMailResultService } from "../../../_services/tenderMailResultService";

const MailTenderResults = ({
  tenderForm,
  setDataFound,
  page,
  setPage,
  setMailTenderTabCount,
}) => {
  const [tenderResultData, setTenderResultData] = React.useState([]);
  const [tenderResultHasMore, setTenderResultHasMore] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [modelData, setModelData] = React.useState([]);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [modalStage, setModalStage] = React.useState("");

  React.useEffect(() => {
    setPage(1);
    setLoader(true);
    mailTenderResultData();
    getCount();
  }, [tenderForm]);

  const mailTenderResultData = async () => {
    try {
      const res = await tenderMailResultService.getTenderMailResult({
        ...tenderForm,
        page_no: page,
        user_id: parseInt(localStorage.getItem("user_id")),
        user_query_id: parseInt(
          localStorage.getItem("user_email_service_query_id")
        ),
      });
      setLoader(false);
      if (res.Success) {
        setLoader(false);
        setPage((prev) => prev + 1);

        if (page === 1) {
          setTenderResultData(res.Data);
        } else {
          setTenderResultData((prev) => [...prev, ...res.Data]);
        }
        if (res.TotalRecord < 20) {
          setTenderResultHasMore(false);
        } else {
          setTenderResultHasMore(true);
        }
      } else {
        setLoader(false);
        setPage((prev) => prev + 0);
        setTenderResultHasMore(false);
        setTenderResultData((prev) => [...prev, ...[]]);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      setTenderResultHasMore(false);
      setTenderResultData((prev) => [...prev, ...[]]);
      setPage((prev) => prev + 0);
    }
  };

  const openParticipatedBidder = (e, tender_result) => {
    setModelOpen(true);
    setModalStage(tender_result.stage.toLowerCase());
    tenderResultService
      .getParticipatingBidder({ result_id: tender_result.result_id })
      .then((res) => {
        if (res.Success) {
          setModelData(
            res.Data.map((obj) => {
              return { ...obj, result_id: tender_result.result_id };
            })
          );
        } else {
          setModelData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCount = async () => {
    const res = await tenderMailResultService.getTenderMailResultCount({
      ...tenderForm,
      page_no: page,
      user_id: parseInt(localStorage.getItem("user_id")),
      user_query_id: parseInt(
        localStorage.getItem("user_email_service_query_id")
      ),
    });

    if (res.Success && res.TotalRecord > 0) {
      setMailTenderTabCount(res.Data);
    }
  };

  return (
    <div className="FreshResult">
      {loader ? (
        <Loader />
      ) : tenderResultData.length > 0 ? (
        <InfiniteScroll
          dataLength={tenderResultData.length}
          hasMore={tenderResultHasMore}
          next={mailTenderResultData}
          loader={
            tenderResultData.length > 19 && (
              <>
                <Skeleton sx={{ bgcolor: "grey.300" }} />
                <Skeleton sx={{ bgcolor: "grey.300" }} />
                <Skeleton sx={{ bgcolor: "grey.300" }} />
                <Skeleton sx={{ bgcolor: "grey.300" }} />
              </>
            )
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more record.</b>
            </p>
          }
          scrollableTarget="scroll"
        >
          <TenderArea
            tenderResultData={tenderResultData}
            openParticipatedBidder={openParticipatedBidder}
            setDataFound={setDataFound}
            fromFavorite={false}
            tenderForm={tenderForm}
            setPage={setPage}
          />
        </InfiniteScroll>
      ) : (
        <div style={{ margin: 0, justifyContent: "center", display: "flex" }}>
          <h3>No Result Found as per your Filter Criteria.</h3>
        </div>
      )}
      <BidderListModal
        modelOpen={modelOpen}
        setModelOpen={setModelOpen}
        modelData={modelData}
        modalStage={modalStage}
      />
    </div>
  );
};

export default MailTenderResults;
