import { http } from "../_apiConfig/http";
import { RouteUrls } from "../_apiConfig/routeUrls";
export const userService = {
  getUserProfileDetail,
  changePassword,
  getUserPlan,
  getKeyManager,
  getInvoiceDetails,
  getIp,
  // getInvoiceDownload,
};

function getUserProfileDetail(data) {
  return http.post(RouteUrls.getUserProfileDetail, data, true);
}

function getUserPlan(data) {
  return http.post(RouteUrls.planUpgrade, data, true);
}
function getKeyManager(data) {
  return http.post(RouteUrls.keyManager, data, true);
}

function changePassword(data) {
  return http.post(RouteUrls.changePassword, data, true);
}
function getInvoiceDetails(data) {
  return http.post(RouteUrls.changePassword, data, true);
}

function getIp() {
  return http.get(RouteUrls.ip, false);
}
