// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
// import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import ThemeLocalization from "./locales";
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import ScrollToTop from "./components/scroll-to-top";
import { AuthProvider } from "./auth/JwtContext";
import { selectedDateRange } from "./layouts/dashboard/header/context-api/Context";
import { useState } from "react";
import { fromDate, toDate } from "./components/date-input/FromDateToDate";
import { useEffect } from "react";

export default function App() {
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(fromDate());
  const [selectedToDate, setSelectedToDate] = useState(toDate());
  const [isYearChange, setIsYearChange] = useState(false);
  const [isDateChange, setIsDateChange] = useState(false);

  useEffect(() => {
    const loadScript = () => {
      // Load Zendesk Chat script dynamically
      const script = document.createElement("script");
      script.src = "//v2.zopim.com/?2pQL1WoOgPcWywsBL5OhjguA4GOOluma";
      script.async = true;
      document.body.appendChild(script);

      // Initialize Zendesk Chat widget
      script.onload = () => {
        window.zE ||
          (function (e, t, n) {
            var s, i, a;
            if (
              ((window.zE = e),
              (e.init = function (e, t, n) {
                var s = e.createElement("script");
                (window.zEmbed = function () {
                  zE._.push(arguments);
                }),
                  (zE._ = []),
                  (s.src =
                    "https://static.zdassets.com/ekr/asset_composer.js?key=" +
                    t),
                  (s.async = !0),
                  s.setAttribute("charset", "utf-8"),
                  s.setAttribute("crossorigin", "*"),
                  (s.onerror = function () {
                    (zE = window.zE),
                      (window.zE = null),
                      console.error(
                        "Error loading Zendesk Chat script. Please check your Zendesk Chat key."
                      );
                  }),
                  (
                    document.getElementsByTagName("head")[0] ||
                    document.getElementsByTagName("body")[0]
                  ).appendChild(s),
                  (a = e.createElement("style")),
                  (a.type = "text/css"),
                  a.appendChild(e.createTextNode(n)),
                  (
                    document.getElementsByTagName("head")[0] ||
                    document.getElementsByTagName("body")[0]
                  ).appendChild(a);
              }),
              (s = document.createElement("script")),
              (s.async = !0),
              s.setAttribute("charset", "utf-8"),
              (s.src = "//v2.zopim.com/?2pQL1WoOgPcWywsBL5OhjguA4GOOluma" + e),
              (i = document.getElementsByTagName("script")[0]),
              i.parentNode.insertBefore(s, i))
            )
              window.zE("webWidget", "setLocale", "en");
            // window.zE("webWidget", "hide");
          })(document, "2pQL1WoOgPcWywsBL5OhjguA4GOOluma", "");
      };
    };

    loadScript();
  }, []);

  return (
    <selectedDateRange.Provider
      value={{
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        isDateSelected,
        setIsDateSelected,
        //**** */
        isYearChange,
        setIsYearChange,
        isDateChange,
        setIsDateChange,
      }}
    >
      <AuthProvider>
        <HelmetProvider>
          {/* <SettingsProvider> */}
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <Router />
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
          {/* </SettingsProvider> */}
        </HelmetProvider>
      </AuthProvider>
    </selectedDateRange.Provider>
  );
}
