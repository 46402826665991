import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Department from "../../../common/filterInputs/Department";
import StateFilter from "../../../common/filterInputs/StateFilter";
import { DatePicker, Form, Space } from "antd";
import RangePickerFilter from "../../../common/filterInputs/RangePicker";
import { dateConvert } from "../../../_helpers/date-format";
import moment from "moment";
import dayjs from "dayjs";

const MailResultFilters = (props) => {
  const {
    setTenderForm,
    setDataFound,
    setIsFilter,
    tenderForm,
    setStateTag,
    stateTag,
    departmentTag,
    setDepartmentTag,
    setFilterTags,
    mailResultDate,
    setMailResultDate,
    setMailTenderTabCount,
    setMailBidderTabCount,
    updateToDate,
    updateFromDate,
    setUpdateFromDate,
    setUpdateToDate,
    setPage,
    formDateRef1,
    formDateRef2,
  } = props;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const theme = useTheme();

  const changeMailDate = (date, dateString) => {
    setMailResultDate(dateString);
  };
  const handleSubmit = () => {
    setPage(1);
    setTenderForm((prev) => ({
      ...prev,
      mail_date: mailResultDate ? mailResultDate : "",
      page_no: 1,
      record_per_page: 20,
      sort_by: 3,
      sort_type: 1,
    }));
    setDataFound(false);
    setIsFilter(true);
    setFilterTags([
      ...stateTag,
      departmentTag,
      mailResultDate
        ? {
            type: "Mail Date",
            title: dateConvert(mailResultDate),
          }
        : null,
      updateFromDate && updateToDate
        ? {
            type: "Status Updated Date",
            title:
              dateConvert(updateFromDate) + " → " + dateConvert(updateToDate),
          }
        : null,
    ]);
  };

  const allClear = () => {
    setPage(1);
    setStateTag([]);
    setDepartmentTag(null);
    setMailTenderTabCount({});
    setMailBidderTabCount({});
    setMailResultDate(moment().format("YYYY-MM-DD"));
    setUpdateFromDate("");
    setUpdateToDate("");
    setFilterTags(
      mailResultDate
        ? [
            {
              type: "Mail Date",
              title: dateConvert(moment().format("YYYY-MM-DD")),
            },
          ]
        : []
    );
    formDateRef1.current?.resetFields();
    formDateRef2.current?.resetFields();
    setTenderForm((prev) => ({
      ...prev,
      mail_date: moment().format("YYYY-MM-DD"),
      page_no: 1,
      record_per_page: 20,
      sort_by: 3,
      sort_type: 1,
    }));
    setDataFound(false);
    setIsFilter(true);
  };
  const disableFutureDates = (current) => {
    return current && current >= moment().endOf("day");
  };

  return (
    <Accordion className="filter-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Filter By</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div className="filter-by-area">
            <div className="filter-form">
              <div className="filter-first">
                <div className="row">
                  <div className="span2 offset1">
                    <div className="search-area">
                      <div className="state-area">
                        <Form form={form1} ref={formDateRef1}>
                          <Form.Item name="Closing Date">
                            <DatePicker
                              style={{ width: "100%", color: "#000" }}
                              placeholder="Mail Date"
                              color="#000"
                              defaultValue={dayjs(moment())}
                              value={mailResultDate}
                              onChange={changeMailDate}
                              disabledDate={disableFutureDates}
                              allowClear={false}
                            />
                          </Form.Item>
                        </Form>
                      </div>

                      <div className="between-area"></div>
                      <div className="four-row"></div>
                      <div className="fiv-row">
                        <a
                          onClick={handleSubmit}
                          style={{
                            backgroundColor: theme.palette.primary.darker,
                            color: theme.palette.common.white,
                          }}
                        >
                          Search
                        </a>
                        <a
                          onClick={allClear}
                          style={{
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              theme.palette.action.selectedOpacity
                            ),
                          }}
                        >
                          Clear
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default MailResultFilters;
