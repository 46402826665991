// import React, { useEffect, useState } from "react";
// import { compareCompetitors } from "../../_services/compareCompetitorsServices";
// import MuiSinglSelect from "../../components/mui-autocomplete/MuiSingleSelect";

// const CompanyName = (props) => {
//   const { companyName, setCompanyName, titleLabel } = props;
//   const [text, setText] = useState("");
//   const [participantList, setParticipantList] = useState([]);

//   const getCompanyName = async () => {
//     try {
//       const res = await compareCompetitors.getCompanyNameForComparison({
//         company_name: text,
//       });

//       res.Success && res.TotalRecord > 0
//         ? setParticipantList(
//             res.Data.map((object) => ({
//               ...object,
//               title: object.bidder_name,
//             })).sort((a, b) => {
//               if (a.bidder_name < b.bidder_name) {
//                 return -1;
//               }
//               if (a.bidder_name > b.bidder_name) {
//                 return 1;
//               }
//               return 0;
//             })
//           )
//         : setParticipantList([]);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };

//   useEffect(() => {
//     text.length >= 3 && getCompanyName();
//   }, [text]);

//   return (
//     // <div className={titleLabel !=="Search Company" ? "state-area" : ""}>
//     <div className={"state-area"}>
//       <MuiSinglSelect
//         setText={setText}
//         name={titleLabel}
//         options={participantList}
//         selectedValue={companyName}
//         setSelectedValue={setCompanyName}
//       />
//     </div>
//   );
// };

// export default CompanyName;

import React, { useEffect, useState, useRef } from "react";
import { compareCompetitors } from "../../_services/compareCompetitorsServices";
import MuiSinglSelect from "../../components/mui-autocomplete/MuiSingleSelect";

const CompanyName = (props) => {
  const { companyName, setCompanyName, titleLabel, pageType } = props;
  const [text, setText] = useState("");
  const [participantList, setParticipantList] = useState([]);
  const debounceTimeout = useRef(null);

  const getCompanyName = async () => {
    try {
      const res = await compareCompetitors.getCompanyNameForComparison({
        company_name: text,
        bidder_id: parseInt(localStorage.getItem("bidder_id")),
        page_type: pageType ? 1 : 0
      });

      res.Success && res.TotalRecord > 0
        ? setParticipantList(
            res.Data.map((object) => ({
              ...object,
              title: object.bidder_name,
            })).sort((a, b) => {
              if (a.bidder_name < b.bidder_name) {
                return -1;
              }
              if (a.bidder_name > b.bidder_name) {
                return 1;
              }
              return 0;
            })
          )
        : setParticipantList([]);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (text.length >= 3) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        getCompanyName();
      }, 500); // Adjust the debounce delay as needed
    } else {
      setParticipantList([]);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [text]);

  return (
    <div className={"state-area"}>
      <MuiSinglSelect
        setText={setText}
        name={titleLabel}
        options={participantList}
        selectedValue={companyName}
        setSelectedValue={setCompanyName}
      />
    </div>
  );
};

export default CompanyName;
