import { notification } from "antd";

export const successNotify = (message = "", width = "400", placement = "topRight") => {
  notification["success"]({
    message: message,
    // description: "",
    placement: placement,
    style: { width: `${width}px` },
  });
};

export const errorNotify = (message = "", width = "400", placement = "topRight") => {
  notification["error"]({
    message: message,
    // description: "",
    placement: placement,
    style: { width: `${width}px` },
  });
};

export const infoNotify = (message = "", width = "400", placement = "topRight") => {
  notification["info"]({
    message: message,
    // description: "",
    placement: placement,
    style: { width: `${width}px` },
  });
};

export const warningNotify = (message = "", width = "400", placement = "topRight") => {
  notification["warning"]({
    message: message,
    // description: "",
    placement: placement,
    style: { width: `${width}px` },
  });
};
