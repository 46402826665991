import * as React from "react";
import "../../assets/style/BS5_Grid.css";
import "../../assets/style/style.css";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useSettingsContext } from "../../components/settings/SettingsContext";
import { useState } from "react";
import { useEffect } from "react";
import { selectedDateRange } from "../../layouts/dashboard/header/context-api/Context";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { setResultListingModel } from "../../redux/slice";
import Filters from "../../common/filters";
import Tags from "../../common/filterChips";
import MissedOpportunities from "./components/MissedOpportunities";
import DowloadExcelBtn from "../../components/download-tender-excel/DowloadExcelBtn";
import TenderSorting from "../../components/tenderSortSelect/TenderSorting";
import { valueConvert } from "../../_helpers/valueConvert";
import { useRef } from "react";

export default function MissedOpportunity() {
  const dispatch = useDispatch();
  const initialListing = useSelector((state) =>
    state.listing_model.initialResultListing !== undefined &&
    state.listing_model.initialResultListing !== null
      ? state.listing_model.initialResultListing
      : state.listing_model.initialListing
  );
  const {
    selectedFromDate,
    selectedToDate,
    setIsDateSelected,
    isDateSelected,
    isYearChange,
  } = useContext(selectedDateRange);

  const { themeStretch } = useSettingsContext();
  const [tenderForm, setTenderForm] = useState(() => {
    const { mail_date, ...restInitialListing } = initialListing;
    return {
      ...restInitialListing,
      tender_status:
        restInitialListing.tender_status !== undefined &&
        restInitialListing.tender_status !== null
          ? restInitialListing.tender_status
          : 0,
      stage:
        restInitialListing.stage !== undefined &&
        restInitialListing.stage !== null &&
        restInitialListing.stage.length > 0
          ? restInitialListing.stage.map((res) => res.stage_id).join(",")
          : "",
    };
  });
  const [dataFound, setDataFound] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [allTags, setAllTags] = useState([]);
  const [participantName, setParticipantName] = useState(null);
  const [winnerBidderName, setWinnerBidderName] = useState(null);
  const [stateTag, setStateTag] = useState(
    initialListing.state_ids !== undefined &&
      initialListing.state_ids !== null &&
      initialListing.state_ids.length > 0
      ? initialListing.state_ids.map((res) => ({
          ...res,
          title: res.state_name,
          type: "State",
        }))
      : []
  );
  const [cityTag, setCityTag] = useState([]);
  const [wordSearch, setWordSearch] = useState("");
  const [tenderOwnershipTag, setTenderOwnershipTag] = useState([]);
  const [departmentTag, setDepartmentTag] = useState(
    initialListing.organization_id !== undefined &&
      initialListing.organization_id !== null &&
      Object.keys(initialListing.organization_id).length > 0
      ? {
          ...initialListing.organization_id,
          title: initialListing.organization_id.organization_name,
          type: "Department",
        }
      : null
  );
  const [awardedValue, setAwardedValue] = useState("");
  const [tenderStageTag, setTenderStageTag] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [valueType, setValueType] = useState("");
  const [tenderValueOperator, setTenderValueOperator] = useState(2);
  const [fromValue, setFromValue] = useState(0);
  const [toValue, setToValue] = useState(0);
  const [keywordTags, setKeyWordTags] = useState(
    initialListing.keyword_ids !== undefined &&
      initialListing.keyword_ids !== null &&
      initialListing.keyword_ids.keyword_name !== undefined &&
      initialListing.keyword_ids.keyword_name !== null &&
      initialListing.keyword_ids.keyword_name.length > 0
      ? {
          ...initialListing.keyword_ids,
          title: initialListing.keyword_ids.keyword_name,
          type: "Select Category",
        }
      : null
  );
  const formRef = useRef(null);
  const [refrenceId, setRefrenceId] = useState("");
  const [isSplitWord, setIsSplitWord] = React.useState(false);
  const [subIndustryTag, setSubIndustryTag] = useState(null);
  const [isSearchBy, setIsSearchBy] = React.useState(false);
  const [websiteType, setWebsiteType] = useState("");
  const [recordCount, setRecordCount] = React.useState({});
  const [publicationDateFrom, setPublicationDateFrom] =
    useState(selectedFromDate);
  const [publicationDateTo, setPublicationDateTo] = useState(selectedToDate);
  useEffect(() => {
    if (isSplitWord) {
      setTenderForm((prev) => ({
        ...prev,
        page_no: 1,
        search_by_split_word: true,
      }));
      setIsSplitWord(false);
    }
  }, [isSplitWord]);

  useEffect(() => {
    setDataFound(false);
    setRecordCount({});
    setPublicationDateFrom(selectedFromDate);
    setPublicationDateTo(selectedToDate);
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    if (isDateSelected || isYearChange) {
      setPage(1);
      setTenderForm((prev) => ({
        ...prev,
        publication_date_from: publicationDateFrom,
        publication_date_to: publicationDateTo,
        search_by: 1,
      }));
      setDataFound(true);
      setIsDateSelected(false);
    } else {
      setTenderForm((prev) => ({
        ...prev,
        publication_date_from: publicationDateFrom,
        publication_date_to: publicationDateTo,
        search_by: 1,
      }));
      setDataFound(true);
    }
  }, [publicationDateFrom, publicationDateTo]);

  useEffect(() => {
    if (isFilter) {
      setDataFound(true);
      setIsFilter(false);
    }
  }, [isFilter]);

  return (
    <>
      <Helmet>
        <title>Missed Opportunity</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={3}>
          <Filters
            setAllTags={setAllTags}
            tenderForm={tenderForm}
            setTenderForm={setTenderForm}
            setDataFound={setDataFound}
            setIsFilter={setIsFilter}
            winnerBidderName={winnerBidderName}
            setWinnerBidderName={setWinnerBidderName}
            participantName={participantName}
            setParticipantName={setParticipantName}
            stateTag={stateTag}
            setStateTag={setStateTag}
            cityTag={cityTag}
            setCityTag={setCityTag}
            wordSearch={wordSearch}
            setWordSearch={setWordSearch}
            tenderOwnershipTag={tenderOwnershipTag}
            setTenderOwnershipTag={setTenderOwnershipTag}
            departmentTag={departmentTag}
            setDepartmentTag={setDepartmentTag}
            awardedValue={awardedValue}
            setAwardedValue={setAwardedValue}
            tenderStageTag={tenderStageTag}
            setTenderStageTag={setTenderStageTag}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            valueType={valueType}
            setValueType={setValueType}
            tenderValueOperator={tenderValueOperator}
            setTenderValueOperator={setTenderValueOperator}
            fromValue={fromValue}
            setFromValue={setFromValue}
            toValue={toValue}
            setToValue={setToValue}
            keywordTags={keywordTags}
            setKeyWordTags={setKeyWordTags}
            refrenceId={refrenceId}
            setRefrenceId={setRefrenceId}
            setPage={setPage}
            setIsSplitWord={setIsSplitWord}
            subIndustryTag={subIndustryTag}
            setSubIndustryTag={setSubIndustryTag}
            setIsSearchBy={setIsSearchBy}
            websiteType={websiteType}
            setWebsiteType={setWebsiteType}
            setRecordCount={setRecordCount}
            isMisOpportunityPage={true}
            formRef={formRef}
          />
          <Tags
            allTags={allTags}
            setAllTags={setAllTags}
            setTenderForm={setTenderForm}
            setWinnerBidderName={setWinnerBidderName}
            setParticipantName={setParticipantName}
            stateTag={stateTag}
            setStateTag={setStateTag}
            cityTag={cityTag}
            setCityTag={setCityTag}
            setWordSearch={setWordSearch}
            tenderOwnershipTag={tenderOwnershipTag}
            setTenderOwnershipTag={setTenderOwnershipTag}
            departmentTag={departmentTag}
            setDepartmentTag={setDepartmentTag}
            setTenderStageTag={setTenderStageTag}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setValueType={setValueType}
            setTenderValueOperator={setTenderValueOperator}
            fromValue={fromValue}
            setFromValue={setFromValue}
            toValue={toValue}
            setToValue={setToValue}
            setKeyWordTags={setKeyWordTags}
            setRefrenceId={setRefrenceId}
            setIsFilter={setIsFilter}
            setDataFound={setDataFound}
            setIsSplitWord={setIsSplitWord}
            setPage={setPage}
            setSubIndustryTag={setSubIndustryTag}
            setIsSearchBy={setIsSearchBy}
            setWebsiteType={setWebsiteType}
            setRecordCount={setRecordCount}
            isMisOpportunityPage={true}
            formRef={formRef}
          />
          <div className="missed-opportunity-area">
            <h2>
              Total Records
              {recordCount.resultcount > 0
                ? ` (${valueConvert(recordCount.resultcount)})`
                : ``}
            </h2>
            <div className="sort-by-main-area">
              {/* <div className="missed-opportunity-sort-by-main-area"> */}
              <DowloadExcelBtn
                tenderForm={tenderForm}
                value={"5"}
                pageName={"tenderListing"}
                setPage={setPage}
                setTenderForm={setTenderForm}
              />
              <TenderSorting
                setDataFound={setDataFound}
                setIsFilter={setIsFilter}
                setTenderForm={setTenderForm}
                setPage={setPage}
              />
            </div>
          </div>

          {dataFound && (
            <MissedOpportunities
              tenderForm={tenderForm}
              setDataFound={setDataFound}
              page={page}
              setPage={setPage}
              isSplitWord={isSplitWord}
              setIsSplitWord={setIsSplitWord}
              isSearchBy={isSearchBy}
              setIsSearchBy={setIsSearchBy}
              setTenderForm={setTenderForm}
              setRecordCount={setRecordCount}
            />
          )}
        </Grid>
      </Container>
    </>
  );
}
