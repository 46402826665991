import moment from "moment";
import { converterDefaultValue } from "../../layouts/dashboard/header/components/DateConvertor";
import dayjs from "dayjs";

export function fromDate() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("datefrom");
  return myParam !== undefined && myParam !== null
    ? myParam
    : localStorage.getItem("from_date") !== null
    ? localStorage.getItem("from_date")
    : fromDefaulDate();
}

export function toDate() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("dateto");
  return myParam !== undefined && myParam !== null
    ? myParam
    : localStorage.getItem("to_date") !== null
    ? localStorage.getItem("to_date")
    : toDefaultDate();
}

export function defaultYear() {
  const urlParams = new URLSearchParams(window.location.search);
  const toDate = urlParams.get("dateto");
  const fromDate = urlParams.get("datefrom");
  if (fromDate && toDate) {
    return dayjs(toDate, "YYYY").year() === dayjs(fromDate, "YYYY").year()
      ? dayjs(toDate, "YYYY").year()
      : currentYear();
  }
  return localStorage.getItem("selectedYear") !== null
    ? localStorage.getItem("selectedYear")
    : currentYear();
}

export function fromDefaulDate() {
  return converterDefaultValue(
    moment().startOf("year")._d.toString().split(" ")
  );
}

export function currentYear() {
  return dayjs(
    converterDefaultValue(moment().startOf("year")._d.toString().split(" "))
  ).year();
}

export function toDefaultDate() {
  return converterDefaultValue(
    moment().endOf("month")._d.toString().split(" ")
  );
}

export function todayDate() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month > 9 ? month : "0" + month}-${year}`;
  return currentDate;
}
